
import serviceReqModel , {serviceReqPlaceholder} from "@/models/service"
import router from '@/router'
import Vue from "vue";
import {Service  , Decline} from "@/repositories/service"
import {Approve} from '@/repositories/user'
export default Vue.extend({
  name: "posts-list",
  data() {
      return {
            loading : true,
            item : serviceReqPlaceholder,
            approvedServiceId: 0,
            msgModal : false,
            dialog : false,
            msg : "",
            viewable : [
                    'Name',
                    'Email',
                    'Phone',
                    'Breif',
                    'CreatedAt',
            ]
      }
  },   
  methods:{
        send(id:number){
                this.approvedServiceId = id
                this.msgModal = true
              
        },
        sendMessage(id:number){
            // Approve( router.currentRoute.path.split('/')[2], id).then((res) => {
            //     this.approvedServiceId = 0
            //     this.msgModal = false
            //     this.goBack()
            // })
            console.log("Asd")
        },
        goBack(){
            router.push({name : "services-pending"})
        }
  },
  created()  {
      Service(parseInt(router.currentRoute.params.id)).then((res:serviceReqModel) => {
          this.item = res
          this.loading = false
      })
  }
});
